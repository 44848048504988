<template>
    <div>
        <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>

        <div v-if="student_Data.ispersonaldetailsfreezed == false" cols="12" sm="2" align="center">
            <span class="blink" style="font-size:16px;font-weight:bold;color:red;">You have not yet Freezed the Personal
                Details,Kindly freeze it.
                (If you have already done it before , Please do it once again)</span>
        </div>
        <div v-else>
            <span style="font-size:16px;font-weight:bold;color:green;"> Personal Details Freezed.</span>
        </div>


        <v-toolbar flat dense class="tc-title">
            <router-link to="student-profile" class="nav-arrow-left"><v-icon
                    class="left-arrow">mdi-arrow-left-bold</v-icon></router-link>
            <v-toolbar-title>Personal Details</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <router-link to="student-addressDetailsDynamic" class="nav-arrow-right"><v-icon
                    class="right-arrow">mdi-forward</v-icon></router-link>
            <a class="blink" href="https://www.youtube.com/watch?v=VVbTYMH_3zA" target="_blank"><span
                    style="color:red;font-size:35px;" class="mdi mdi-message-video"></span></a>
        </v-toolbar>
        <v-card v-if="student_Data.ispersonaldetailsfreezed == false">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="3" v-if="student_Data.Gr_No_Dynamic.isActive && student_Data.Gr_No_Dynamic">
                        
                        <span>
                            <label>{{ student_Data.Gr_No_Dynamic.displayname }}</label><span><strong
                                    style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.grno }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.First_Name_Dynamic.isActive && student_Data.First_Name_Dynamic">
                        <span v-if="student_Data.First_Name_Dynamic.isEditable">
                            <span v-if="student_Data.First_Name_Dynamic.isRequired">
                                <v-text-field :rules="nameRules" placeholder="Enter First Name"
                                    oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"
                                    v-model="student_Data.fname" @change="fullname()" outlined>
                                <template #label>
                                    <label>{{ student_Data.First_Name_Dynamic.displayname }}</label>
                                    <strong style="color: red;">*</strong>:
                                </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"
                                    v-model="student_Data.fname" @change="fullname()" outlined>
                                <template #label>
                                    <label>{{ student_Data.First_Name_Dynamic.displayname }}</label> :
                                </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.First_Name_Dynamic.displayname }}</label><span
                                v-if="student_Data.First_Name_Dynamic.isRequired"><strong
                                    style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.fname }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Middle_Name_Dynamic.isActive && student_Data.Middle_Name_Dynamic">
                        <span v-if="student_Data.Middle_Name_Dynamic.isEditable">
                            <span v-if="student_Data.Middle_Name_Dynamic.isRequired">
                                <v-text-field :rules="nameRules" placeholder="Enter Middle Name"
                                    oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"
                                    v-model="student_Data.mname" @change="fullname()" outlined>
                                <template #label>
                                    <label>{{ student_Data.Middle_Name_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"
                                    v-model="student_Data.mname" @change="fullname()" outlined>
                                <template #label>
                                    <label>{{ student_Data.Middle_Name_Dynamic.displayname }}</label> :
                                </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Middle_Name_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Middle_Name_Dynamic.isRequired"> <strong
                                style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.mname }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Last_Name_Dynamic.isActive && student_Data.Last_Name_Dynamic">
                        <span v-if="student_Data.Last_Name_Dynamic.isEditable">
                            <span v-if="student_Data.Last_Name_Dynamic.isRequired">
                                <v-text-field :rules="nameRules" placeholder="Enter Last Name"
                                    oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"
                                    v-model="student_Data.lname" @change="fullname()" outlined>
                                <template #label>
                                    <label>{{ student_Data.Last_Name_Dynamic.displayname }}</label><strong
                                    style="color: red;"> *</strong> :
                                </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field oninput="this.value = this.value.replace(/[^A-Za-z]/, '')"
                                    v-model="student_Data.lname" @change="fullname()" outlined>
                                <template #label>
                                    <label>{{ student_Data.Last_Name_Dynamic.displayname }}</label> :
                                </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Last_Name_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Last_Name_Dynamic.isRequired"> <strong
                                style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.lname }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Full_Name_Dynamic.isActive && student_Data.Full_Name_Dynamic">
                        <span>
                            <label>{{ student_Data.Full_Name_Dynamic.displayname }}</label>
                            <span> <strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.fullname }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Gender_Dynamic.isActive && student_Data.Gender_Dynamic">
                        <span v-if="student_Data.Gender_Dynamic.isEditable">
                            <span v-if="student_Data.Gender_Dynamic.isRequired">
                                <v-select :items="g_List" v-model="student_Data.gender" item-text="name"
                                    item-value="student_Data.gender" required outlined>
                                <template #label>
                                    <label>{{ student_Data.Gender_Dynamic.displayname }}</label><strong style="color: red;">*</strong> :
                                </template>
                                </v-select>
                            </span>
                            <span v-else>
                                <v-select :items="g_List" v-model="student_Data.gender" item-text="name"
                                    item-value="student_Data.gender" outlined>
                                <template #label>
                                    <label>{{ student_Data.Gender_Dynamic.displayname }}</label> :
                                </template>
                                </v-select>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Gender_Dynamic.displayname }}</label>  
                            <span v-if="student_Data.Gender_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.gender }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.dob_Dynamic.isActive && student_Data.dob_Dynamic">
                        <span v-if="student_Data.dob_Dynamic.isEditable">
                            <span v-if="student_Data.dob_Dynamic.isRequired">
                                <v-text-field type="date" required placeholder="Select Date"
                                    v-model="student_Data.bdate" outlined>
                                    <template #label>
                                        <label>{{ student_Data.dob_Dynamic.displayname }}</label><strong style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field type="date" placeholder="Select Date" outlined v-model="student_Data.bdate">
                                    <template #label>
                                        <label>{{ student_Data.dob_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field >
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.dob_Dynamic.displayname }}</label>
                            <span v-if="student_Data.dob_Dynamic.isRequired"><strong style="color: red;">*</strong> :</span>
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.bdate }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.year_Dynamic.isActive && student_Data.year_Dynamic">
                        <span>
                            <label>{{ student_Data.year_Dynamic.displayname }}</label>
                            <span>
                               <strong style="color: red;">*</strong> 
                            </span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.year }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Dept_Dynamic.isActive && student_Data.Dept_Dynamic">
                        <span>
                            <label>{{ student_Data.Dept_Dynamic.displayname }}</label>
                            <span>
                            <strong style="color: red;">*</strong>
                            </span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.department }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Program_Dynamic.isActive && student_Data.Program_Dynamic">
                        <span>
                            <label>{{ student_Data.Program_Dynamic.displayname }}</label>
                            <span><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.program }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.YP_Dynamic.isActive && student_Data.YP_Dynamic">
                        <span v-if="student_Data.YP_Dynamic.isEditable">
                            <span v-if="student_Data.YP_Dynamic.isRequired">
                                <v-autocomplete :items="aylist" v-model="student_Data.yearofpassing" item-text="ay"
                                    item-value="ay_id" :rules="[v => !!v || 'required']" outlined>
                                <template #label>
                                    <label>{{ student_Data.YP_Dynamic.displayname }}</label> <strong style="color: red;">*</strong> :
                                </template>
                                </v-autocomplete>
                            </span>
                            <span v-else>
                                <v-autocomplete :items="aylist" v-model="student_Data.yearofpassing" item-text="ay"
                                    item-value="ay_id" outlined>
                                <template #label>
                                    <label>{{ student_Data.YP_Dynamic.displayname }}</label> :
                                </template>
                                </v-autocomplete>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.YP_Dynamic.displayname }}</label>
                            <span v-if="student_Data.YP_Dynamic.isRequired"><strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.yearofpassingay }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.BG_Dynamic.isActive && student_Data.BG_Dynamic">
                        <span v-if="student_Data.BG_Dynamic.isEditable">
                            <span v-if="student_Data.BG_Dynamic.isRequired">
                                <v-autocomplete :items="b_List" v-model="student_Data.bgroup" item-text="b_group"
                                    item-value="id" :rules="[v => !!v || 'required']" outlined>
                                <template #label>
                                    <label>{{ student_Data.BG_Dynamic.displayname }}</label><strong style="color: red;">*</strong> :
                                </template>
                                </v-autocomplete>
                            </span>
                            <span v-else>
                                <v-autocomplete :items="b_List" v-model="student_Data.bgroup" item-text="b_group"
                                    item-value="id" outlined>
                                <template #label>
                                    <label>{{ student_Data.BG_Dynamic.displayname }}</label>
                                </template>
                                </v-autocomplete>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.BG_Dynamic.displayname }}</label>
                            <span v-if="student_Data.BG_Dynamic.isRequired"><strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.bgroupname }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Nationality_Dynamic.isActive && student_Data.Nationality_Dynamic">
                        <span v-if="student_Data.Nationality_Dynamic.isEditable">
                            <span v-if="student_Data.Nationality_Dynamic.isRequired">
                                <v-autocomplete :items="nationality_List" v-model="student_Data.nationality"
                                    item-text="name" item-value="id" :rules="[v => !!v || 'required']" outlined>
                                <template #label>
                                    <label>{{ student_Data.Nationality_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                </template>
                                </v-autocomplete>
                            </span>
                            <span v-else>
                                <v-autocomplete :items="nationality_List" v-model="student_Data.nationality"
                                    item-text="name" item-value="id" outlined>
                                <template #label>
                                    <label>{{ student_Data.Nationality_Dynamic.displayname }}</label> :
                                </template>
                                </v-autocomplete>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Nationality_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Nationality_Dynamic.isRequired">
                                <strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.nationality_name }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Personal_Email_Dynamic.isActive && student_Data.Personal_Email_Dynamic">
                        <span v-if="student_Data.Personal_Email_Dynamic.isEditable">
                            <span v-if="student_Data.Personal_Email_Dynamic.isRequired">
                                <v-text-field type="email" :error-messages="error.student_Data.pemail"
                                    placeholder="Enter Personal Email" :rules="emailRules"
                                    oninput="this.value = this.value.replace(/[^a-z0-9@.]/, '')"
                                    v-model="student_Data.pemail" outlined>
                                <template #label>
                                    <label>{{ student_Data.Personal_Email_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field type="email" oninput="this.value = this.value.replace(/[^a-z0-9@.]/, '')"
                                    v-model="student_Data.pemail" outlined>
                                    <template #label>
                                    <label>{{ student_Data.Personal_Email_Dynamic.displayname }}</label> :
                                </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Personal_Email_Dynamic.displayname }}</label>  
                            <span v-if="student_Data.Personal_Email_Dynamic.isRequired"><strong
                                style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.pemail }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Inst_Email_Dynamic.isActive && student_Data.Inst_Email_Dynamic">
                        <span>
                            <label>{{ student_Data.Inst_Email_Dynamic.displayname }}</label>
                            <span><strong
                                style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.iemail }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Cont_Dynamic.isActive && student_Data.Cont_Dynamic">
                        <span v-if="student_Data.Cont_Dynamic.isEditable">
                            <span v-if="student_Data.Cont_Dynamic.isRequired">
                                <v-text-field type="number" required :value="0" min="0" :rules="[rules.validateContact]"  maxlength="10" hint="Enter exactly 10 digits"
                                    v-model="student_Data.contact" outlined>
                                    <template #label>
                                    <label>{{ student_Data.Cont_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field type="number"  maxlength="10" :rules="[rules.validateContact]" :value="0" min="0" hint="Enter exactly 10 digits"
                                    v-model="student_Data.contact" outlined>
                                    <template #label>
                                    <label>{{ student_Data.Cont_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Cont_Dynamic.displayname }}</label> 
                            <span v-if="student_Data.Cont_Dynamic.isRequired">
                                <strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.contact }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Alt_Cont_Dynamic.isActive && student_Data.Alt_Cont_Dynamic">
                        <span v-if="student_Data.Alt_Cont_Dynamic.isEditable">
                            <span v-if="student_Data.Alt_Cont_Dynamic.isRequired">
                                <v-text-field type="number" required :value="0" min="0"
                                    hint="Enter 10 digit Number Only" outlined
                                    :rules="[rules.validateContact]"  maxlength="10" 
                                    v-model="student_Data.alternate_cont">
                                    <template #label>
                                    <label>{{ student_Data.Alt_Cont_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field type="number" :value="0" min="0" hint="Enter 10 digit Number Only"
                                    v-model="student_Data.alternate_cont" outlined :rules="[rules.validateContact]"  maxlength="10" >
                                    <template #label>
                                    <label>{{ student_Data.Alt_Cont_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Alt_Cont_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Alt_Cont_Dynamic.isRequired"><strong
                                style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.alternate_cont }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Lang_Dynamic.isActive && student_Data.Lang_Dynamic">
                        <span v-if="student_Data.Lang_Dynamic.isEditable">
                            <span v-if="student_Data.Lang_Dynamic.isRequired">
                                <v-select :items="l_List" v-model="getlang" multiple item-text="name"
                                    item-value="id" :rules="[v => !!v || 'required']" outlined>
                                    <template #label>
                                    <label>{{ student_Data.Lang_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-select>
                            </span>
                            <span v-else>
                                <v-select :items="l_List" v-model="getlang" multiple item-text="name"
                                    item-value="id" outlined>
                                <template #label>
                                <label>{{ student_Data.Lang_Dynamic.displayname }}</label> :
                                </template>
                                </v-select>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Lang_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Lang_Dynamic.isRequired">
                                <strong style="color: red;">*</strong>
                            </span> :
                            <v-row no-gutters> <span v-for="(item, index) in student_Data.language" :key="index"
                                    class="lbl"> <span v-if="index != 0">,</span> {{ item.name }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Tech_Dynamic.isActive && student_Data.Tech_Dynamic">
                        <span v-if="student_Data.Tech_Dynamic.isEditable">
                            <span v-if="student_Data.Tech_Dynamic.isRequired">
                                <v-text-field placeholder="Enter Technical Interest" v-model="student_Data.technical"
                                    density="compact" :rules="[ruless, rules.isEmpty]" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32 "
                                    :error-messages="error.student_Data.technical ? 'Please enter valid technical skills' : ''">
                                    <template #label>
                                    <label>{{ student_Data.Tech_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Technical Interest" v-model="student_Data.technical" outlined
                                    density="compact" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode
                                < 123) || event.charCode == 44 || event.charCode == 32 ">
                             <template #label>
                                    <label>{{ student_Data.Tech_Dynamic.displayname }}</label> :
                                    </template>    
                            </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Tech_Dynamic.displayname }}</label> 
                              <span v-if="student_Data.Tech_Dynamic.isRequired"><strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl">{{ student_Data.technical }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Strength_Dynamic.isActive && student_Data.Strength_Dynamic">
                        <span v-if="student_Data.Strength_Dynamic.isEditable">
                            <span v-if="student_Data.Strength_Dynamic.isRequired">
                                <v-text-field placeholder="Enter Strength" v-model="student_Data.strenght"
                                    :rules="[ruless, rules.isEmpty]" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 ||  event.charCode == 32"
                                    :error-messages="error.student_Data.strenght ? 'Please Enter Valid Strength' : ''">
                                    <template #label>
                                    <label>{{ student_Data.Strength_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Strength" v-model="student_Data.strenght" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91)
                                || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 ||  event.charCode == 32">
                                <template #label>
                                    <label>{{ student_Data.Strength_Dynamic.displayname }}</label> :
                                    </template>    
                            </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Strength_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Strength_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.strenght }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Weakness_Dynamic.isActive && student_Data.Weakness_Dynamic">
                        <span v-if="student_Data.Weakness_Dynamic.isEditable">
                            <span v-if="student_Data.Weakness_Dynamic.isRequired">
                                <v-text-field placeholder="Enter Weakness" v-model="student_Data.weakness"
                                    :rules="[ruless, rules.isEmpty]" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 ||  event.charCode == 32"
                                    :error-messages="error.student_Data.weakness ? 'Please Enter weakness' : ''">
                                    <template #label>
                                    <label>{{ student_Data.Weakness_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Weakness" v-model="student_Data.weakness" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91) 
                                || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 ||  event.charCode == 32">
                            <template #label>
                                    <label>{{ student_Data.Weakness_Dynamic.displayname }}</label> :
                                    </template>    
                            </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Weakness_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Weakness_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.weakness }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Hobbies_Dynamic.isActive && student_Data.Hobbies_Dynamic">
                        <span v-if="student_Data.Hobbies_Dynamic.isEditable">
                            <span v-if="student_Data.Hobbies_Dynamic.isRequired">
                                <v-text-field placeholder="Enter Hobbies" v-model="student_Data.hobbies"
                                    :rules="[ruless, rules.isEmpty]" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32"
                                    :error-messages="error.student_Data.hobbies ? 'Please Enter Valid Hobbies' : ''">
                                    <template #label>
                                    <label>{{ student_Data.Hobbies_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Hobbies" v-model="student_Data.hobbies" outlined
                                    onkeypress="return (event.charCode > 64 && event.charCode < 91)
                                || (event.charCode > 96 && event.charCode < 123) || event.charCode == 44 || event.charCode == 32">
                             <template #label>
                                    <label>{{ student_Data.Hobbies_Dynamic.displayname }}</label> :
                                    </template>    
                            </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Hobbies_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Hobbies_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.hobbies }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Aadhar_Dynamic.isActive && student_Data.Aadhar_Dynamic">
                        <span v-if="student_Data.Aadhar_Dynamic.isEditable">
                            <span v-if="student_Data.Aadhar_Dynamic.isRequired">
                                <v-text-field :rules="[rules.validateAadhar]" placeholder="Enter Adhar Number" outlined
                                    v-model="student_Data.adhar"
                                    oninput="this.value = this.value.replace(/[^0-9]/, '')">
                                    <template #label>
                                    <label>{{ student_Data.Aadhar_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Adhar Number" v-model="student_Data.adhar" outlined
                                    oninput="this.value = this.value.replace(/[^0-9]/, '')" :rules="[rules.validateAadhar]">
                                    <template #label>
                                    <label>{{ student_Data.Aadhar_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Aadhar_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Aadhar_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.adhar }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3"
                        v-if="student_Data.Passport_Dynamic.isActive && student_Data.Passport_Dynamic">
                        <span v-if="student_Data.Passport_Dynamic.isEditable">
                            <span v-if="student_Data.Passport_Dynamic.isRequired">
                                <v-text-field :rules="[ruless, rules.isEmpty]" placeholder="Enter Passport Number"
                                    v-model="student_Data.passport" outlined
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9]/, '')">
                                    <template #label>
                                    <label>{{ student_Data.Passport_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Passport Number" v-model="student_Data.passport" outlined
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9]/, '')">
                                    <template #label>
                                    <label>{{ student_Data.Passport_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Passport_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Passport_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.passport }} </span> </v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.Pan_No_Dynamic.isActive && student_Data.Pan_No_Dynamic">
                        <span v-if="student_Data.Pan_No_Dynamic.isEditable">
                            <span v-if="student_Data.Pan_No_Dynamic.isRequired">
                                <v-text-field :rules="[rules.validatePan]" maxlength="10" placeholder="Enter Pan card Number"
                                    v-model="student_Data.pan" outlined
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9,-_]/, '')">
                                    <template #label>
                                    <label>{{ student_Data.Pan_No_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field placeholder="Enter Pan card Number" maxlength="10" v-model="student_Data.pan" outlined
                                    oninput="this.value = this.value.replace(/[^A-Za-z0-9,-_]/, '')" :rules="[rules.validatePan]">
                                    <template #label>
                                    <label>{{ student_Data.Pan_No_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Pan_No_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Pan_No_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.pan }} </span> </v-row>
                        </span>
                    </v-col>

                    <v-col cols="12" sm="3" v-if="student_Data.Parent_working_company_Dynamic.isActive && student_Data.Parent_working_company_Dynamic">
                        <span v-if="student_Data.Parent_working_company_Dynamic.isEditable">
                            <span v-if="student_Data.Parent_working_company_Dynamic.isRequired">
                                <v-text-field :rules="[ruless, rules.isEmpty]"
                                    v-model="student_Data.parent_working_company" outlined
                                    oninput="this.value = this.value.replace(`[^A-Za-z0-9,-_]`, '')">
                                    <template #label>
                                    <label>{{ student_Data.Parent_working_company_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field  v-model="student_Data.parent_working_company" outlined
                                    oninput="this.value = this.value.replace(`[^A-Za-z0-9,-_]`, '')">
                                    <template #label>
                                    <label>{{ student_Data.Parent_working_company_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Parent_working_company_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Parent_working_company_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.parent_working_company }} </span> </v-row>
                        </span>
                    </v-col>

                    <v-col cols="12" sm="3" v-if="student_Data.Parent_designation_Dynamic.isActive && student_Data.Parent_designation_Dynamic">
                        <span v-if="student_Data.Parent_designation_Dynamic.isEditable">
                            <span v-if="student_Data.Parent_designation_Dynamic.isRequired">
                                <v-text-field :rules="[ruless, rules.isEmpty]"
                                    v-model="student_Data.parent_designation" outlined
                                    oninput="this.value = this.value.replace(`[^A-Za-z0-9,-_]`, '')">
                                    <template #label>
                                    <label>{{ student_Data.Parent_designation_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field  v-model="student_Data.parent_designation" outlined
                                    oninput="this.value = this.value.replace(`[^A-Za-z0-9,-_]`, '')">
                                    <template #label>
                                    <label>{{ student_Data.Parent_designation_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Parent_designation_Dynamic.displayname }}</label>
                            <span v-if="student_Data.Parent_designation_Dynamic.isRequired"><strong style="color: red;">*</strong></span> :
                            <v-row no-gutters> <span class="lbl">{{ student_Data.parent_designation }} </span> </v-row>
                        </span>
                    </v-col>

                    <v-col cols="12" sm="3" v-if="student_Data.Parent_Contact_Dynamic.isActive && student_Data.Parent_Contact_Dynamic">
                        <span v-if="student_Data.Parent_Contact_Dynamic.isEditable">
                            <span v-if="student_Data.Parent_Contact_Dynamic.isRequired">
                                <v-text-field type="number" required :value="0" min="0" hint="Enter Number Only"
                                    v-model="student_Data.parent_contact" outlined>
                                    <template #label>
                                    <label>{{ student_Data.Parent_Contact_Dynamic.displayname }}</label><strong
                                    style="color: red;">*</strong> :
                                    </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field type="number" :value="0" min="0" hint="Enter Number Only"
                                    v-model="student_Data.parent_contact" outlined>
                                    <template #label>
                                    <label>{{ student_Data.Parent_Contact_Dynamic.displayname }}</label> :
                                    </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Parent_Contact_Dynamic.displayname }}</label> 
                            <span v-if="student_Data.Parent_Contact_Dynamic.isRequired">
                                <strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.parent_contact }}</span></v-row>
                        </span>
                    </v-col>

                    <v-col cols="12" sm="6" v-if="student_Data.Dream_company_Dynamic.isActive">
                        <span v-if="student_Data.Dream_company_Dynamic.isEditable">
                            <span v-if="student_Data.Dream_company_Dynamic.isRequired">
                                <v-text-field readonly v-model="student_Data.dream_company" outlined
                                @click="add_dream_company()" required>
                                <template #label>
                                <label>{{student_Data.Dream_company_Dynamic.displayname}}</label><small style="color: red;">*</small> :
                                </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field readonly v-model="student_Data.dream_company" outlined
                                @click="add_dream_company()">
                                <template #label>
                                <label>{{student_Data.Dream_company_Dynamic.displayname}}</label><small style="color: red;"></small> :
                                </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Dream_company_Dynamic.displayname }}</label> 
                            <span v-if="student_Data.Dream_company_Dynamic.isRequired">
                                <strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.dream_company }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="student_Data.Interested_Ref_Dynamic.isActive">
                        <span v-if="student_Data.Interested_Ref_Dynamic.isEditable">
                            <span v-if="student_Data.Interested_Ref_Dynamic.isRequired">
                                <v-text-field readonly v-model="student_Data.interested_references" outlined
                                @click="add_interested_ref()" required>
                                <template #label>
                                <label>{{student_Data.Interested_Ref_Dynamic.displayname}}</label><small style="color: red;">*</small> :
                                </template>
                                </v-text-field>
                            </span>
                            <span v-else>
                                <v-text-field readonly v-model="student_Data.interested_references" outlined
                                @click="add_interested_ref()">
                                <template #label>
                                <label>{{student_Data.Interested_Ref_Dynamic.displayname}}</label><small style="color: red;"></small> :
                                </template>
                                </v-text-field>
                            </span>
                        </span>
                        <span v-else>
                            <label>{{ student_Data.Interested_Ref_Dynamic.displayname }}</label> 
                            <span v-if="student_Data.Interested_Ref_Dynamic.isRequired">
                                <strong style="color: red;">*</strong></span>:
                            <v-row no-gutters> <span class="lbl"> {{ student_Data.interested_references }}</span></v-row>
                        </span>
                    </v-col>
                    <v-col cols="12" sm="6" v-if="student_Data.is_interested_district_location_Dynamic.isActive">
                        <h4><input type="checkbox"
                        v-model="student_Data.is_interested_district_location"
                        color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                        />&nbsp;&nbsp;{{student_Data.is_interested_district_location_Dynamic.displayname}}</h4>
                    </v-col>
                      <v-col cols="12" sm="6" v-if="student_Data.is_any_academic_gap_Dynamic.isActive">
                        <h4><input type="checkbox"
                        v-model="student_Data.is_any_academic_gap"
                        color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                        />&nbsp;&nbsp;{{student_Data.is_any_academic_gap_Dynamic.displayname}}</h4>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="3">
                        <label>Registration Number :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.grno }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3" v-if="student_Data.fname == null">
                        <label> First Name<span class="required">*</span> :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.fname }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3" v-else>
                        <label> First Name :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.fname }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Middle Name :</label>
                        <v-row no-gutters><span class="lbl">{{ student_Data.mname }}</span></v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Last Name :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.lname }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Full Name :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.fullname }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Gender :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.gender }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Date of Birth :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.bdate }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Year :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.year }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Department :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.department }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Program :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.program }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Year of Passing<span class="required">*</span> :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.yearofpassingay }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Select Blood Group<span class="required">*</span> :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.bgroupname }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Select Nationality<span class="required">*</span> :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.nationality_name }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Personal Email :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.pemail }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Institute Email :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.iemail }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Contact Number :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.contact }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Alternate Contact Number :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.alternate_cont }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Language :</label>
                        <v-row no-gutters> <span v-for="(item, index) in student_Data.language" :key="index"
                                class="lbl"> <span v-if="index != 0">,</span> {{ item.name }} </span> </v-row>
                    </v-col>

                    <!-- <label>Is Year Gap after 10th <span class="required"></span> :</label>
                     <span v-if="student_Data.isyeardown == true">: Yes</span><span v-else >: NO</span> -->
                    <!-- </v-col> -->
                    <!-- <label>Are you placed? <span class="required"></span> :</label>
                     <span v-if="student_Data.isplaced == true">: Yes</span><span v-else >: NO</span> -->

                    <!-- <label>Have you received any internship? <span class="required"></span> :</label>
                     <span v-if="student_Data.isintern == true">: Yes</span><span v-else >: NO</span>
                      </v-col> -->

                    <v-col cols="12" sm="3">
                        <label>Technical Interest :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.technical }}</span> </v-row>
                    </v-col>

                    <v-col cols="12" sm="3">
                        <label>Strength :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.strenght }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Weakness :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.weakness }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Hobbies :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.hobbies }}</span> </v-row>
                    </v-col>
                    <!-- <v-col cols="12" sm="3">
                      <label>Describe Yourself  :</label>
                      <v-row no-gutters> <span class="lbl" > {{student_Data.yourself}}</span>  </v-row >
                </v-col> -->

                    <!-- <v-col cols="12" sm="3">
                     <label>Career Objective :</label>
                     <v-row no-gutters> <span class="lbl" > {{student_Data.objective}}</span>  </v-row >
                </v-col> -->
                    <v-col cols="12" sm="3">
                        <label>Adhar Number :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.adhar }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Passport Number :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.passport }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>PAN Number :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.pan }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Parent Working Company :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.parent_working_company }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Parent Designation :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.parent_designation }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Parent Contact :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.parent_contact }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Dream Company :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.dream_company }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <label>Give Any two Industrial References :</label>
                        <v-row no-gutters> <span class="lbl"> {{ student_Data.interested_references }}</span> </v-row>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <h4><input type="checkbox"
                        v-model="student_Data.is_interested_district_location"
                        color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                        />&nbsp;&nbsp;{{student_Data.is_interested_district_location_Dynamic.displayname}}</h4>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <h4><input type="checkbox"
                        v-model="student_Data.is_any_academic_gap"
                        color="success" style="margin: 0.4rem; width:18px; height: 15px;"
                        />&nbsp;&nbsp;{{student_Data.is_any_academic_gap_Dynamic.displayname}}</h4>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- <v-row v-if="student_Data.ispersonaldetailsfreezed == false">
            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="tc-title">Local Address<span class="mr-1" style="color: red;"></span>:
                    </v-card-title>
                    <v-row>
                        <v-col col-sm="6"
                            v-if="student_Data.Local_Add_Dynamic.isActive && student_Data.Local_Add_Dynamic">
                            <span v-if="student_Data.Local_Add_Dynamic.isEditable">
                                <span v-if="student_Data.Local_Add_Dynamic.isRequired">
                                    <v-textarea label="Address*" v-model="local.currentaddress" style="margin: 8px;"
                                        class="pt-0 pb-0" :rules="[ruless, rules.isEmpty]" outlined
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 47 || event.charCode == 38 || event.charCode == 44 ||  event.charCode == 32"
                                        :error-messages="error.local.currentaddress ? 'Please Enter Valid Addresss' : ''">
                                        <template #label>
                                            <p>{{ student_Data.Local_Add_Dynamic.displayname }}<span
                                                    style="color:red">*</span></p>
                                        </template>
                                    </v-textarea>
                                </span>
                                <span v-else>
                                    <v-textarea label="Address" v-model="local.currentaddress" style="margin: 8px;"
                                        class="pt-0 pb-0" outlined
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 47 || event.charCode == 38 || event.charCode == 44 ||  event.charCode == 32">
                                        <template #label>
                                            <p>{{ student_Data.Local_Add_Dynamic.displayname }}</p>
                                        </template>
                                    </v-textarea>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ml-1">{{ student_Data.Local_Add_Dynamic.displayname }}:</span> {{
            local.currentaddress }}
                            </span>
                        </v-col>
                        <v-col col-sm="6"
                            v-if="(student_Data.Local_Pin_Dynamic.isActive && student_Data.Local_Pin_Dynamic) || (student_Data.Local_Country_Dynamic.isActive && student_Data.Local_Country_Dynamic)">
                            <span v-if="student_Data.Local_Pin_Dynamic.isEditable">
                                <span v-if="student_Data.Local_Pin_Dynamic.isRequired">
                                    <v-text-field label="Pin*" v-model="local.currentpin"
                                        :rules="[ruless, rules.isEmpty]" outlined
                                        oninput="this.value = this.value.replace(/[^0-9]/, '')" style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Local_Pin_Dynamic.displayname }}<span
                                                    style="color:red">*</span></p>
                                        </template>
                                    </v-text-field>
                                </span>
                                <span v-else>
                                    <v-text-field label="Pin*" v-model="local.currentpin" outlined
                                        oninput="this.value = this.value.replace(/[^0-9]/, '')" style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Local_Pin_Dynamic.displayname }}</p>
                                        </template>
                                    </v-text-field>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ml-1">{{ student_Data.Local_Pin_Dynamic.displayname }}:</span> {{
            local.currentpin }}
                            </span>
                            <span v-if="student_Data.Local_Country_Dynamic.isEditable">
                                <span v-if="student_Data.Local_Country_Dynamic.isRequired">
                                    <v-select v-model="local.currentcountry" :rules="[ruless, rules.isEmpty]" outlined
                                        :items="country_list" item-text="state" label="Country*" dense style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Local_Country_Dynamic.displayname }}<span
                                                    style="color:red">*</span></p>
                                        </template>
                                    </v-select>
                                </span>
                                <span v-else>
                                    <v-select v-model="local.currentcountry" :items="country_list" item-text="state" outlined
                                        label="Country*" dense style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Local_Country_Dynamic.displayname }}</p>
                                        </template>
                                    </v-select>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ml-1">{{ student_Data.Local_Country_Dynamic.displayname }}:</span> {{
            local.currentcountry }}
                            </span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12">
                <h4><input type="checkbox" v-model="checkbox" color="success"
                        style="margin: 0.4rem; width:18px; height: 15px; margin-left: 30%;"
                        @change="copyadd(checkbox)" />&nbsp;&nbsp;Whether Permanent Address is same as Local Address
                </h4>
            </v-col>
            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="tc-title">Permanent Address<span class="mr-1" style="color: red;"></span>:
                    </v-card-title>
                    <v-row>
                        <v-col col-sm="6" v-if="student_Data.Per_Add_Dynamic.isActive && student_Data.Per_Add_Dynamic">
                            <span v-if="student_Data.Per_Add_Dynamic.isEditable">
                                <span v-if="student_Data.Per_Add_Dynamic.isRequired">
                                    <v-textarea label="Address*" v-model="permanent.permanentaddress"
                                        style="margin: 8px;" class="pt-0 pb-0" :rules="[ruless, rules.isEmpty]" outlined
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 47 || event.charCode == 38 || event.charCode == 44 ||  event.charCode == 32"
                                        :error-messages="error.permanent.permanentaddress ? 'Please Enter Valid Addresss' : ''">
                                        <template #label>
                                            <p>{{ student_Data.Per_Add_Dynamic.displayname }}<span
                                                    style="color:red">*</span></p>
                                        </template>
                                    </v-textarea>
                                </span>
                                <span v-else>
                                    <v-textarea label="Address" v-model="permanent.permanentaddress"
                                        style="margin: 8px;" class="pt-0 pb-0" outlined
                                        onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.keyCode >=48 && event.keyCode <= 57) || event.charCode == 47 || event.charCode == 38 || event.charCode == 44 ||  event.charCode == 32">
                                        <template #label>
                                            <p>{{ student_Data.Per_Add_Dynamic.displayname }}</p>
                                        </template>
                                    </v-textarea>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ml-1">{{ student_Data.Per_Add_Dynamic.displayname }}:</span> {{
            permanent.permanentaddress }}
                            </span>
                        </v-col>
                        <v-col col-sm="6"
                            v-if="(student_Data.Per_Pin_Dynamic.isActive && student_Data.Per_Pin_Dynamic) || (student_Data.Per_Country_Dynamic.isActive && student_Data.Per_Country_Dynamic)">
                            <span v-if="student_Data.Per_Pin_Dynamic.isEditable">
                                <span v-if="student_Data.Per_Pin_Dynamic.isRequired">
                                    <v-text-field label="Pin*" v-model="permanent.permanentpin"
                                        :rules="[ruless, rules.isEmpty]" outlined
                                        oninput="this.value = this.value.replace(/[^0-9]/, '')" style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Per_Pin_Dynamic.displayname }}<span
                                                    style="color:red">*</span></p>
                                        </template>
                                    </v-text-field>
                                </span>
                                <span v-else>
                                    <v-text-field label="Pin*" v-model="permanent.permanentpin" outlined
                                        oninput="this.value = this.value.replace(/[^0-9]/, '')" style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Per_Pin_Dynamic.displayname }}</p>
                                        </template>
                                    </v-text-field>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ml-1">{{ student_Data.Per_Pin_Dynamic.displayname }}:</span> {{
            permanent.permanentpin }}
                            </span>
                            <span v-if="student_Data.Per_Country_Dynamic.isEditable">
                                <span v-if="student_Data.Per_Country_Dynamic.isRequired">
                                    <v-select v-model="permanent.permanentcountry" :rules="[ruless, rules.isEmpty]" outlined
                                        :items="country_list" item-text="state" label="Country*" dense style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Per_Country_Dynamic.displayname }}<span
                                                    style="color:red">*</span></p>
                                        </template>
                                    </v-select>
                                </span>
                                <span v-else>
                                    <v-select v-model="permanent.permanentcountry" :items="country_list" outlined
                                        item-text="state" label="Country*" dense style="margin: 8px;">
                                        <template #label>
                                            <p>{{ student_Data.Per_Country_Dynamic.displayname }}</p>
                                        </template>
                                    </v-select>
                                </span>
                            </span>
                            <span v-else>
                                <span class="ml-1">{{ student_Data.Per_Country_Dynamic.displayname }}:</span> {{
            permanent.permanentcountry }}
                            </span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" sm="12" class="mt-3">
                <v-card>
                    <v-card-title class="tc-title">Local Address<span style="color: red;"></span>: </v-card-title>
                    <v-row>
                        <v-col col-sm="6">
                            <v-row no-gutters> <span class="lbl ml-2"> <b style="color: black;">Address :</b>
                                    {{ local.currentaddress }}</span> </v-row>
                            <v-row no-gutters> <span class="lbl ml-2"> <b style="color: black;">Pin :</b>
                                    {{ local.currentpin }}</span> </v-row>
                            <v-row no-gutters> <span class="lbl ml-2"> <b style="color: black;">Country :</b>
                                    {{ local.currentcountry }}</span> </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12">
                <v-card>
                    <v-card-title class="tc-title">Permanent Address<span class="mr-1" style="color: red;"></span>:
                    </v-card-title>
                    <v-row>

                        <v-col col-sm="2">
                            <v-row no-gutters><span class="lbl ml-2"> <b style="color: black;">Address :</b>
                                    {{ permanent.permanentaddress }}</span> </v-row>
                            <v-row no-gutters><span class="lbl ml-2"> <b style="color: black;">Pin :</b>
                                    {{ permanent.permanentpin }}</span> </v-row>
                            <v-row no-gutters><span class="lbl ml-2"> <b style="color: black;">Country :</b>
                                    {{ permanent.permanentcountry }}</span> </v-row>
                        </v-col>

                    </v-row>
                </v-card>
            </v-col>
        </v-row> -->

        <div v-if="student_Data.ispersonaldetailsfreezed == false" align="center">
            <v-col cols="12" sm="3" align="center"></v-col>
            <v-col v-if="student_Data.ispersonaldetailsfreezed == false" cols="12" sm="5" align="center">
                <v-btn v-if="saved" style="margin-top:20px" color="warning darken-1" dark
                    @click="freezepersonaldetails()">Freeze</v-btn> &nbsp;
                <v-btn style="margin-top:20px" color="primary darken-1" dark @click="save()">Save</v-btn><br>
                <span v-if="saved" style="font-size:16px;font-weight:bold;color:blue;"><br>NOTE : Once you freeze the
                    details,it
                    can not be changed.</span><br>
            </v-col>
            <v-col v-else cols="12" sm="2" align="center"><br>
                <span style="font-size:16px;font-weight:bold;color:red;">You Have Already Freezed the Details.</span>
            </v-col>
        </div>
        <div v-else>
            <v-row>
                <v-col cols="12" sm="5" align="center"></v-col>
                <v-col v-if="student_Data.ispersonaldetailsfreezed == false" cols="12" sm="2" align="center">
                    <span style="font-size:16px;font-weight:bold;color:blue;">NOTE : Once you freeze the details, it can
                        not be
                        changed.</span>
                    <v-btn style="margin-top:20px" color="primary darken-1" dark @click="save()">Save & Freeze</v-btn>
                </v-col>
                <v-col v-else cols="12" sm="2" align="center"><br>
                    <span style="font-size:16px;font-weight:bold;color:red;">You Have Freezed the Details.</span>
                    <v-btn style="margin-top:20px" color="primary darken-1" dark
                        @click="unfreezeStudentdetails('personal')">Unfreeze</v-btn>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="add_dream_company_dialog" width="40%">
    <v-card style="width: 700px;">
        <v-card-title class="card-title1">Add Dream Company</v-card-title>
        <br>
        <v-card-text>
            <div v-for="(company, index) in student_Data.dream_companies" :key="index" style="display: flex; align-items: center;">
            <v-row>
                <v-col cols="9">
                <v-text-field 
                    outlined 
                    v-model="company.name" 
                    style="flex: 1;"
                >
                    <template #label>Company</template>
                </v-text-field>
                </v-col>
                <v-col cols="3">
                <v-icon size="26"
                    @click="addCompanyField"
                    class="mx-2 mt-4" 
                    color="primary"
                    style="cursor: pointer;"
                >
                    mdi-plus-circle
                </v-icon>
           
                <v-icon size="26"
                    @click="removeCompanyField(index)"
                    class="mx-2 mt-4"  
                    color="red" 
                    style="cursor: pointer;"
                >
                    mdi-delete
                </v-icon>
            </v-col>
            </v-row>
            </div>
            <center>
            <v-btn class="primary" @click="submitCompanies">Add</v-btn>
            <v-btn class="ml-4" @click="add_dream_company_dialog=false">Cancel</v-btn>
            </center>
        </v-card-text>
    </v-card>
</v-dialog>

<v-dialog v-model="add_interested_ref_dialog" width="40%">
    <v-card style="width: 700px;">
        <v-card-title class="card-title1">Add Interested References</v-card-title>
        <br>
        <v-card-text>
            <div v-for="(references, index) in student_Data.Interested_ref" :key="index" style="display: flex; align-items: center;">
            <v-row>
                <v-col cols="9">
                <v-text-field 
                    outlined 
                    v-model="references.name" 
                    style="flex: 1;"
                >
                    <template #label>Interested References</template>
                </v-text-field>
                </v-col>
                <v-col cols="3">
                <v-icon size="26"
                    @click="addInterestedField"
                    class="mx-2 mt-4" 
                    color="primary"
                    style="cursor: pointer;"
                >
                    mdi-plus-circle
                </v-icon>
           
                <v-icon size="26"
                    @click="removeInterestedField(index)"
                    class="mx-2 mt-4"  
                    color="red" 
                    style="cursor: pointer;"
                >
                    mdi-delete
                </v-icon>
            </v-col>
            </v-row>
            </div>
            <center>
            <v-btn class="primary" @click="submitInterested">Add</v-btn>
            <v-btn class="ml-4" @click="add_interested_ref_dialog=false">Cancel</v-btn>
            </center>
        </v-card-text>
    </v-card>
</v-dialog>


    </div>
</template>
<script>
import StudentProfile from './StudentProfile'
import Nevigation from './Nevigation'
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import datetime from 'vuejs-datetimepicker'
import axios from 'axios'

export default {
    components: {
        StudentProfile,
        Datepicker,
        datetime,
        Nevigation
    },
    data() {
        return {
            ruless: [
                (value) => {
                    if (!value) {
                        return [];
                    } else if (/^\d+$/.test(value)) {
                        return "Numeric values are not allowed";
                    } else {
                        return true;
                    }
                },
            ],
            rules: {
                isEmpty: (value) => !!value || 'Field is required',
                validateContact: value =>
                    value && value.toString().length === 10
                        ? true
                        : "Contact number must be exactly 10 digits",
                validateAadhar: value =>
                     value && value.length === 12
                        ? true
                        : "Aadhar number must be exactly 12 digits",
                validatePan: value =>
                    /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)
                    ? true
                    : "Enter a valid PAN (e.g., ABCDE1234F)",
            },
            nameRules: [
                v => !!v || 'Enter valid name',
                v => /^[a-zA-Z]+$/.test(v) || 'name must be valid'
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /^(([^<>()[\]\\.,;:\s\@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
            ],
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: '',
            add_dream_company_dialog: false,
            add_interested_ref_dialog: false,
            student_Data: {
                ispersonaldetailsfreezed: false,
                grno: "",
                Gr_No_Dynamic: "",
                fname: "",
                First_Name_Dynamic: "",
                mname: "",
                Middle_Name_Dynamic: "",
                lname: "",
                Last_Name_Dynamic: "",
                fullname: "",
                Full_Name_Dynamic: "",
                gender: "",
                Gender_Dynamic: "",
                bdate: "",
                dob_Dynamic: "",
                year: "",
                year_Dynamic: "",
                department: "",
                Dept_Dynamic: "",
                program: "",
                Program_Dynamic: "",
                yearofpassing: "",
                yearofpassingay: "",
                YP_Dynamic: "",
                bgroup: "",
                bgroupname: "",
                BG_Dynamic: "",
                nationality: "",
                nationality_name: "",
                Nationality_Dynamic: "",
                pemail: "",
                Personal_Email_Dynamic: "",
                iemail: "",
                Inst_Email_Dynamic: "",
                contact: "",
                Cont_Dynamic: "",
                alternate_cont: "",
                Alt_Cont_Dynamic: "",
                Lang_Dynamic: "",
                language: [],
                technical: "",
                Tech_Dynamic: "",
                strenght: "",
                Strength_Dynamic: "",
                weakness: "",
                Weakness_Dynamic: "",
                hobbies: "",
                Hobbies_Dynamic: "",
                adhar: "",
                Aadhar_Dynamic: "",
                passport: "",
                Passport_Dynamic: "",
                pan: "",
                parent_working_company: "",
                Pan_No_Dynamic: "",
                // Local_Add_Dynamic: "",
                // Local_Pin_Dynamic: "",
                // Local_Country_Dynamic: "",
                // Per_Add_Dynamic: "",
                // Per_Pin_Dynamic: "",
                // Per_Country_Dynamic: "",
                ispersonaldetailsfreezed: false,
                parent_working_company: "",
                parent_designation: "",
                Parent_designation_Dynamic: "",
                parent_contact: "",
                Parent_Contact_Dynamic: "",
                dream_companies: [{ name: '' }] ,
                dream_company: "",
                Dream_company_Dynamic: "",
                Interested_ref: [{ name: '' }] ,
                interested_references: "",
                Interested_Ref_Dynamic: "",
                is_interested_district_location_Dynamic: "",
                is_interested_district_location: null,
                is_any_academic_gap_Dynamic: "",
                is_any_academic_gap: null,
                year_name: "",
                saved: false,
            },
            getlang: [],
            g_List: [],
            y_List: [],
            d_List: [],
            p_List: [],
            aylist: [],
            b_List: [],
            l_List: [],
            nationality_List: [],
            // checkbox: false,
            error: {
                student_Data: {
                    fname: false,
                    mname: false,
                    lname: false,
                    pemail: false,
                    iemail: false,
                    technical: false,
                    strenght: false,
                    adhar: false,
                },
                // local: {
                //     currentaddress: false,
                //     currentpin: false,
                //     currentcountry: false,
                // },
                // permanent: {
                //     permanentaddress: false,
                //     permanentpin: false,
                //     permanentcountry: false,
                // }
            },
            // local: {
            //     currentcountry: null,
            //     currentpin: null,
            //     currentaddress: null,
            // },
            // permanent: {
            //     permanentaddress: null,
            //     permanentpin: null,
            //     permanentcountry: null,

            // },
            // addressdata: [],
            // country_list: [],
        }
    },
    mounted() {
        this.onLoad()
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        onLoad() {
            axios
                .post("/LearnerData/learnerPersonalDetailsMobileApp")
                .then(res => {
                    if (res.data.msg == "200") {
                        this.g_List = res.data.fields.Gender.gender_options;
                        this.y_List = res.data.fields.Year.year_List;
                        this.d_List = res.data.fields.Department.department_List;
                        this.p_List = res.data.fields.Program.program_List;
                        this.aylist = res.data.fields.Year_Of_Passing.yeararray;
                        this.b_List = res.data.fields.Blood_group.bloodgroup_List;
                        this.nationality_List = res.data.fields.Nationality.nationality_List;
                        this.l_List = res.data.fields.Language.laguage_List;
                        // this.country_list = res.data.country_list;

                        this.student_Data.grno = res.data.fields.Gr_No.value;
                        this.student_Data.Gr_No_Dynamic = res.data.fields.Gr_No;
                        this.student_Data.fname = res.data.fields.First_Name.value;
                        this.student_Data.First_Name_Dynamic = res.data.fields.First_Name;
                        this.student_Data.mname = res.data.fields.Middle_Name.value;
                        this.student_Data.Middle_Name_Dynamic = res.data.fields.Middle_Name;
                        this.student_Data.lname = res.data.fields.Last_Name.value;
                        this.student_Data.Last_Name_Dynamic = res.data.fields.Last_Name;
                        this.student_Data.fullname = res.data.fields.Full_Name.value;
                        this.student_Data.Full_Name_Dynamic = res.data.fields.Full_Name;
                        this.student_Data.gender = res.data.fields.Gender.value;
                        this.student_Data.Gender_Dynamic = res.data.fields.Gender;
                        this.student_Data.bdate = res.data.fields.Date_Of_Birth.value;
                        this.student_Data.dob_Dynamic = res.data.fields.Date_Of_Birth;
                        this.student_Data.year = res.data.fields.Year.value;
                        this.student_Data.year_Dynamic = res.data.fields.Year;
                        this.student_Data.department = res.data.fields.Department.value;
                        this.student_Data.Dept_Dynamic = res.data.fields.Department;
                        this.student_Data.program = res.data.fields.Program.value;
                        this.student_Data.Program_Dynamic = res.data.fields.Program;
                        this.student_Data.yearofpassing = res.data.fields.Year_Of_Passing.value_id;
                        this.student_Data.YP_Dynamic = res.data.fields.Year_Of_Passing;
                        this.student_Data.yearofpassingay = res.data.fields.Year_Of_Passing.value;
                        this.student_Data.bgroup = res.data.fields.Blood_group.value_id;
                        this.student_Data.bgroupname = res.data.fields.Blood_group.value;
                        this.student_Data.BG_Dynamic = res.data.fields.Blood_group;
                        this.student_Data.nationality_name = res.data.fields.Nationality.value;
                        this.student_Data.nationality = res.data.fields.Nationality.value_id;
                        this.student_Data.Nationality_Dynamic = res.data.fields.Nationality;
                        this.student_Data.pemail = res.data.fields.Personal_Email.value;
                        this.student_Data.Personal_Email_Dynamic = res.data.fields.Personal_Email;
                        this.student_Data.iemail = res.data.fields.Inst_Email.value;
                        this.student_Data.Inst_Email_Dynamic = res.data.fields.Inst_Email;
                        this.student_Data.contact = res.data.fields.Contact.value;
                        this.student_Data.Cont_Dynamic = res.data.fields.Contact;
                        this.student_Data.alternate_cont = res.data.fields.Alternate_Contact.value;
                        this.student_Data.Alt_Cont_Dynamic = res.data.fields.Alternate_Contact;
                        this.getlang = res.data.fields.Language.value;
                        this.student_Data.Lang_Dynamic = res.data.fields.Language;
                        this.student_Data.language = res.data.fields.Language.value1;
                        this.student_Data.technical = res.data.fields.Technical.value;
                        this.student_Data.Tech_Dynamic = res.data.fields.Technical;
                        this.student_Data.strenght = res.data.fields.Strength.value;
                        this.student_Data.Strength_Dynamic = res.data.fields.Strength;
                        this.student_Data.weakness = res.data.fields.Weakness.value;
                        this.student_Data.Weakness_Dynamic = res.data.fields.Weakness;
                        this.student_Data.hobbies = res.data.fields.Hobbies.value;
                        this.student_Data.Hobbies_Dynamic = res.data.fields.Hobbies;
                        this.student_Data.adhar = res.data.fields.Aadhar_No.value;
                        this.student_Data.Aadhar_Dynamic = res.data.fields.Aadhar_No;
                        this.student_Data.passport = res.data.fields.Passport_No.value;
                        this.student_Data.Passport_Dynamic = res.data.fields.Passport_No;
                        this.student_Data.pan = res.data.fields.Pan_No.value;
                        this.student_Data.parent_working_company = res.data.fields.Parent_working_company.value;
                        this.student_Data.Pan_No_Dynamic = res.data.fields.Pan_No;


                        // this.local.currentaddress = res.data.currentaddress;
                        // this.student_Data.Local_Add_Dynamic = res.data.Local_Address;
                        // this.student_Data.Local_Pin_Dynamic = res.data.Local_Pin;
                        // this.student_Data.Local_Country_Dynamic = res.data.Local_Country;
                        // this.local.currentpin = res.data.currentpin;
                        // this.local.currentcountry = res.data.currentcountry;
                        // this.permanent.permanentaddress = res.data.permanentaddress;
                        // this.permanent.permanentpin = res.data.permanentpin;
                        // this.permanent.permanentcountry = res.data.permanentcountry;
                        // this.student_Data.Per_Add_Dynamic = res.data.Permanent_Address;
                        // this.student_Data.Per_Pin_Dynamic = res.data.Permanent_Pin;
                        // this.student_Data.Per_Country_Dynamic = res.data.Permanent_Country;

                        this.student_Data.ispersonaldetailsfreezed = res.data.ispersonaldetailsfreezed;
                        this.student_Data.Parent_working_company_Dynamic = res.data.fields.Parent_working_company;
                        this.student_Data.parent_designation = res.data.fields.Parent_designation.value;
                        this.student_Data.Parent_designation_Dynamic = res.data.fields.Parent_designation;
                        this.student_Data.parent_contact = res.data.fields.Parent_contact.value;
                        this.student_Data.Parent_Contact_Dynamic = res.data.fields.Parent_contact;
                        this.student_Data.dream_company = res.data.fields.Dream_company.value;
                        this.student_Data.Dream_company_Dynamic = res.data.fields.Dream_company;
                        this.student_Data.interested_references = res.data.fields.Interested_Ref.value;
                        this.student_Data.Interested_Ref_Dynamic = res.data.fields.Interested_Ref;
                        this.student_Data.is_interested_district_location_Dynamic = res.data.fields.Is_Interested_District_Location;
                        this.student_Data.is_interested_district_location = res.data.fields.Is_Interested_District_Location.value;
                        this.student_Data.is_any_academic_gap_Dynamic = res.data.fields.Is_Any_Academic_Gap;
                        this.student_Data.is_any_academic_gap = res.data.fields.Is_Any_Academic_Gap.value;

                    } else {
                        this.showSnackbar("#b71c1c", "No Data");
                    }
                })
                .catch(error => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                })
                .finally(() => {
                    // 'use strict';
                });
        },
        fullname() {
            this.student_Data.fullname = this.student_Data.fname + " " + this.student_Data.mname + " " + this.student_Data.lname
        },
        // copyadd(item) {
        //     if (item == true) {
        //         this.permanent.permanentaddress = this.local.currentaddress;
        //         this.permanent.permanentpin = this.local.currentpin;
        //         this.permanent.permanentcountry = this.local.currentcountry;
        //     } else if (item == false) {
        //         this.permanent.permanentaddress = "";
        //         this.permanent.permanentpin = "";
        //         this.permanent.permanentcountry = "";
        //     }

        // },

        freezepersonaldetails() {
            if (confirm("Are you sure, Want to Freeze Details, Once Freezed cannot be edited?")) {
                axios
                    .post("/LearnerData/freezepersonaldetails")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.dialog_edit = false;
                            this.showSnackbar("#4caf50", "Details Freezed Successfully.");
                            this.student_Data.ispersonaldetailsfreezed = res.data.ispersonaldetailsfreezed;

                        } else {
                            this.showSnackbar("#b71c1c", "Error While Freeze Data");
                        }
                    })
                    .catch((error) => {
                        // window.console.log(error);
                    });
            }
        },

        save() {
            this.student_Data.language = this.getlang;
            var checkbirthdate = new Date().setYear(new Date().getYear() + 1900 - 17)
            var birthdate = new Date(this.student_Data.bdate);

          
            if (this.student_Data.First_Name_Dynamic.isRequired) {
                if (!this.student_Data.fname) {
                    this.showSnackbar("#b71c1c", "Please Enter First Name ...");
                    return
                }
            }

            if (this.student_Data.Middle_Name_Dynamic.isRequired) {
                if (!this.student_Data.mname) {
                    this.showSnackbar("#b71c1c", "Please Enter Middle Name ...");
                    return
                }
            }
            if (this.student_Data.Last_Name_Dynamic.isRequired) {
                if (!this.student_Data.lname) {
                    this.showSnackbar("#b71c1c", "Please Enter Last Name ...");
                    return
                }
            }
           
            if (this.student_Data.Gender_Dynamic.isRequired) {
                if (this.student_Data.gender == null || this.student_Data.gender == "") {
                    this.showSnackbar("#b71c1c", "Please Select Gender...");
                    return;
                }
            }
            if (this.student_Data.dob_Dynamic.isRequired) {
                if (this.student_Data.bdate == null || this.student_Data.bdate == "") {
                    this.showSnackbar("#b71c1c", "Please Select Date of Birth...");
                    return;
                }
            }
           
            if (this.student_Data.YP_Dynamic.isRequired) {
                if (this.student_Data.yearofpassing == null || this.student_Data.yearofpassing == "") {
                    this.showSnackbar("#b71c1c", "Please Select Year of Passing...");
                    return;
                }
            }
            if (this.student_Data.BG_Dynamic.isRequired) {
                if (this.student_Data.bgroup == null || this.student_Data.bgroup == "") {
                    this.showSnackbar("#b71c1c", "Please Select Blood Group...");
                    return;
                }
            }
            if (this.student_Data.Nationality_Dynamic.isRequired) {
                if (this.student_Data.nationality == null || this.student_Data.nationality == "") {
                    this.showSnackbar("#b71c1c", "Please Select Nationality...");
                    return;
                }
            }
            if (this.student_Data.Personal_Email_Dynamic.isRequired) {
                if (this.student_Data.pemail == null || this.student_Data.pemail == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Personal Email...");
                    return;
                }

                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.student_Data.pemail || this.student_Data.pemail.trim() === '' || !emailRegex.test(this.student_Data.pemail.trim())) {
                    this.showSnackbar("#b71c1c", "Enter valid Personal Email Id...");
                    return;
                }
            }
            if (this.student_Data.Cont_Dynamic.isRequired) {
                if (this.student_Data.contact == null || this.student_Data.contact == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Contact Number...");
                    return;
                }

                if (this.student_Data.contact < 0){
                    this.showSnackbar("#b71c1c", "Enter Valid Contact Number ");
                    return;
                }
                
                if(this.student_Data.contact && (this.student_Data.contact.length < 10 || this.student_Data.contact.length >10)){
                    this.showSnackbar("#b71c1c", "Enter 10 digit Contact Number ");
                    return;
                }
            }
            else{
                if(this.student_Data.contact && (this.student_Data.contact.length < 10 || this.student_Data.contact.length >10)){
                    this.showSnackbar("#b71c1c", "Enter 10 digit Contact Number ");
                    return;
                }
            }
            if (this.student_Data.Alt_Cont_Dynamic.isRequired) {
                if (this.student_Data.alternate_cont == null || this.student_Data.alternate_cont == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Alternate Contact Number...");
                    return;
                }

                if(this.student_Data.alternate_cont && (this.student_Data.alternate_cont.length < 10 || this.student_Data.alternate_cont.length >10)){
                    this.showSnackbar("#b71c1c", "Enter 10 digit Alternate Contact Number ");
                    return;
                }
            }
            else{
                if(this.student_Data.alternate_cont && (this.student_Data.alternate_cont.length < 10 || this.student_Data.alternate_cont.length >10)){
                    this.showSnackbar("#b71c1c", "Enter 10 digit Alternate Contact Number ");
                    return;
                }
            }
            if (this.student_Data.Lang_Dynamic.isRequired) {
                if (this.student_Data.language == null || this.student_Data.language == "") {
                    this.showSnackbar("#b71c1c", "Please Select Language...");
                    return;
                }
            }
            if (this.student_Data.Tech_Dynamic.isRequired) {
                if (this.student_Data.technical == null || this.student_Data.technical == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Technical Interests...");
                    return;
                }
            }
            if (this.student_Data.Strength_Dynamic.isRequired) {
                if (this.student_Data.strenght == null || this.student_Data.strenght == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Strenght...");
                    return;
                }
            }
            if (this.student_Data.Weakness_Dynamic.isRequired) {
                if (this.student_Data.weakness == null || this.student_Data.weakness == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Weakness...");
                    return;
                }
            }
            if (this.student_Data.Hobbies_Dynamic.isRequired) {
                if (this.student_Data.hobbies == null || this.student_Data.hobbies == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Hobbies...");
                    return;
                }
            }
            if (this.student_Data.Aadhar_Dynamic.isRequired) {
                if (this.student_Data.adhar == null || this.student_Data.adhar == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Aadhaar Number...");
                    return;
                }

                if (this.student_Data.adhar && (this.student_Data.adhar.length < 12 || this.student_Data.adhar.length >12)) {
                    this.showSnackbar("#b71c1c", "Enter a valid 12-digit Aadhaar Number");
                    return;
                }
            }
            else{
                if (this.student_Data.adhar && (this.student_Data.adhar.length < 12 || this.student_Data.adhar.length >12)) {
                    this.showSnackbar("#b71c1c", "Enter a valid 12-digit Aadhaar Number");
                    return;
                }
            }
            if (this.student_Data.Passport_Dynamic.isRequired) {
                if (this.student_Data.passport == null || this.student_Data.passport == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Passport Number...");
                    return;
                }
            }
            if (this.student_Data.Pan_No_Dynamic.isRequired) {
                if (this.student_Data.pan == null || this.student_Data.pan == "") {
                    this.showSnackbar("#b71c1c", "Please Enter PAN Number...");
                    return;
                }

                if (this.student_Data.pan && (this.student_Data.pan.length < 10 || this.student_Data.pan.length >10)) {
                    this.showSnackbar("#b71c1c",  "Enter 10-digit alphanumeric PAN Number ");
                    return;
                }
            }
            else{
                if (this.student_Data.pan && (this.student_Data.pan.length < 10 || this.student_Data.pan.length >10)) {
                    this.showSnackbar("#b71c1c",  "Enter 10-digit alphanumeric PAN Number ");
                    return;
                } 
            }
            if (this.student_Data.Parent_working_company_Dynamic.isRequired) {
                if (this.student_Data.parent_working_company == null || this.student_Data.parent_working_company == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Parent Working Company...");
                    return;
                }
            }
            if (this.student_Data.Parent_designation_Dynamic.isRequired) {
                if (this.student_Data.parent_designation == null || this.student_Data.parent_designation == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Parent Designation...");
                    return;
                }
            }
            if (this.student_Data.Parent_Contact_Dynamic.isRequired) {
                if (this.student_Data.parent_contact == null || this.student_Data.parent_contact == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Parent Contact...");
                    return;
                }
            }
            if (this.student_Data.Dream_company_Dynamic.isRequired) {
                if (this.student_Data.dream_company == null || this.student_Data.dream_company == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Dream Company...");
                    return;
                }
            }
            if (this.student_Data.Interested_Ref_Dynamic.isRequired) {
                if (this.student_Data.interested_references == null || this.student_Data.interested_references == "") {
                    this.showSnackbar("#b71c1c", "Please Enter Interested References...");
                    return;
                }
            }
            
            // if (this.student_Data.Local_Add_Dynamic.isRequired) {
            //     if (this.local.currentaddress == null || this.local.currentaddress == "" || /^[ \d]+$/.test(this.local.currentaddress) || this.hasWhiteSpace(this.local.currentaddress)) {
            //         this.showSnackbar("#b71c1c", "Enter Current Address ");
            //         return;
            //     }
            // }
            // if (this.student_Data.Local_Pin_Dynamic.isRequired) {
            //     if (this.local.currentpin == null || this.local.currentpin == "") {
            //         this.showSnackbar("#b71c1c", "Enter Current Pin ");
            //         return;
            //     }
            // }
            // if (this.student_Data.Local_Country_Dynamic.isRequired) {
            //     if (this.local.currentcountry == null || this.local.currentcountry == "") {
            //         this.showSnackbar("#b71c1c", "Select Current Country ");
            //         return;
            //     }
            // }

            // if (this.student_Data.Per_Add_Dynamic.isRequired) {
            //     if (this.permanent.permanentaddress == null || this.permanent.permanentaddress == "" || /^[ \d]+$/.test(this.permanent.permanentaddress) || this.hasWhiteSpace(this.permanent.permanentaddress)) {
            //         this.showSnackbar("#b71c1c", "Enter Permanent Address ");
            //         return;
            //     }
            // }
            // if (this.student_Data.Per_Pin_Dynamic.isRequired) {
            //     if (this.permanent.permanentpin == null || this.permanent.permanentpin == "") {
            //         this.showSnackbar("#b71c1c", "Enter Permanent Pin ");
            //         return;
            //     }
            // }
            // if (this.student_Data.Per_Country_Dynamic.isRequired) {
            //     if (this.permanent.permanentcountry == null || this.permanent.permanentcountry == "") {
            //         this.showSnackbar("#b71c1c", "Select Permanent Country ");
            //         return;
            //     }
            // }

            const data = {
                studentdata: this.student_Data,
                // local: this.local,
                // permanent: this.permanent,
            };
            axios
                .post("/LearnerData/savePersonalDetails", data)
                .then(res => {
                    if (res.data.msg == "200") {
                        this.onLoad();
                        this.saved = true
                        this.showSnackbar("#4caf50", "Personal Details Save Successfully..."); // show snackbar on success
                    } else {
                        this.showSnackbar("#b71c1c", "Something went wrong...");
                    }
                })
                .catch(error => {
                    //window.console.log(error);
                });
            // this.close();L
        },

        unfreezeStudentdetails(item) {
            if (confirm("Are you sure, you want to unfreeze ?")) {
                const data = {
                    value: item,
                };
                axios
                    .post("/LearnerData/unfreezeStudentdetailsstudentside", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.onLoad();
                            this.showSnackbar("#4caf50", "Details Unfreezed Successfully."); // show snackbar on success
                            this.student_Data.ispersonaldetailsfreezed = res.data.ispersonaldetailsfreezed;
                        } else {
                            // this.editAllData.splice(this.editAllData);
                            // this.showSnackbar("#b71c1c", "Record Already Present!!!");
                        }
                    })
                    .catch((error) => {
                        //window.console.log(error);
                    });
            }
        },
        hasWhiteSpace(s) {
            var status = false;
            var reWhiteSpace = new RegExp(/^\s+$/);

            // Check for white space
            if (reWhiteSpace.test(s)) {
                // alert("Please Check Your Fields For Spaces");
                //   return true;
                return /^\s/.test(s);
            }
            //   return false;
            return /^\s/.test(s);
        },

        add_dream_company(){
            if (this.student_Data.dream_company) {
            const companyNames = this.student_Data.dream_company.split(',').map(name => name.trim());
            this.student_Data.dream_companies = companyNames.map(name => ({ name }));
            } else {
            this.student_Data.dream_companies = [{ name: '' }];
            }
            this.add_dream_company_dialog = true;
        },
        addCompanyField() {
            this.student_Data.dream_companies.push({ name: '' });
        },
        removeCompanyField(index) {
            if (this.student_Data.dream_companies.length > 1) {
                this.student_Data.dream_companies.splice(index, 1);
            }
        },
        submitCompanies() {
            // Generate the comma-separated string of company names
            const companies = this.student_Data.dream_companies
                .map((company) => company.name.trim())
                .filter((name) => name !== '');
            
            this.student_Data.dream_company = companies.join(', ');

            // Close the dialog
            this.add_dream_company_dialog = false;
        },


        add_interested_ref(){
            if (this.student_Data.interested_references) {
            const companyNames = this.student_Data.interested_references.split(',').map(name => name.trim());
            this.student_Data.Interested_ref = companyNames.map(name => ({ name }));
            } else {
            this.student_Data.Interested_ref = [{ name: '' }];
            }
            this.add_interested_ref_dialog = true;
        },
        addInterestedField() {
            this.student_Data.Interested_ref.push({ name: '' });
        },
        removeInterestedField(index) {
            if (this.student_Data.Interested_ref.length > 1) {
                this.student_Data.Interested_ref.splice(index, 1);
            }
        },
        submitInterested() {
            // Generate the comma-separated string of company names
            const references = this.student_Data.Interested_ref
                .map((interested) => interested.name.trim())
                .filter((name) => name !== '');
            
            this.student_Data.interested_references = references.join(', ');

            // Close the dialog
            this.add_interested_ref_dialog = false;
        },  

    }
};

</script>
<style scoped>
.nav-arrow-left {
    margin-right: 5px;
    text-decoration: none;
    color: #FFF;
}

.nav-arrow-right {
    margin: 5px;
    text-decoration: none;
    color: rgb(3, 55, 167);
}

.left-arrow {
    color: #FFF;
}

.right-arrow {
    color: rgb(3, 55, 167);
}

.example {
    margin-top: 5px;
    background: #ffff;
    border-bottom: 1px solid black;
    color: black;
}

.ec-title {
    background-image: linear-gradient(-100deg, #f4faba, #d64907);
    color: #FFF;
}

.c-title {
    background-image: linear-gradient(-90deg, #befcf7, #03948d);
    color: #FFF;
}

.tc-title {
    background-image: linear-gradient(-90deg, #FFF, #057996);
    color: #FFF;
    border-radius: 3px;
}

.logi {
    background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
    color: #FFF;
}

.card-title {
    background-image: linear-gradient(-90deg, #8edffd, #034c70);
    color: #FFF;
}

.del-card-title {
    background-image: linear-gradient(-90deg, #fde7e7, #ac0404);
    color: #FFF;
}

.lbl {
    color: #1705e6;
}

.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.blink {
    animation: blinker 0.77s linear infinite;
    color: red;
    font-family: sans-serif;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.tc-title1 {
    background-image: linear-gradient(-90deg, #057996);
    color: #fff;
    border-radius: 3px;
}
.card-title1 {
    background: linear-gradient(to top right, rgba(49, 49, 69, 0.5), rgba(27, 132, 167, 0.5)), 
    url("~@/assets/lapt.jpg");
    background-size: cover; /* Optional: Adjust background size as needed */
    background-position: center; /* Optional: Adjust background position as needed */
    color: #fff;
}
</style>